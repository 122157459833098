import React from 'react'
import img2 from './img/img2.png';
import'./Hero.css';

const hero = () => {
  return (
    <>
    {/* <div className='bannerimage' > </div> */}
    <div class="container">
  <div class="row">
    <div class="column-66">
      <h1 class="large-font">Connecting Technologies..</h1>
      <p>Botsot Consultancy Pvt Ltd is a leading systems integrator company that specializes in the design, implementation, and maintenance of complex technology systems. We have a team of highly skilled and experienced professionals who work closely with our clients to understand their unique needs and goals, and then deliver customized solutions that are tailored to their specific requirements. Our areas of expertise include IT Infrastructure, Low-Voltage, Automation, Clean energy, Power-Backup's With a proven track record of delivering results, we are dedicated to helping our clients achieve their objectives and stay ahead of the competition.



</p>
    </div>
    <div class="column-33">
        <img src={img2} alt =''/>
    </div>
  </div>
</div>
    </>
   
  )
}

export default hero;