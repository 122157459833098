import React from 'react';
import img2 from '../component/img/img2.png';


const About = () => {
  return (
    <>
<div className='bannerimage' > </div>
    <div class="container">
  <div class="row">
    <div class="column-66">
    <h1 class="large-font">About</h1>
      <p>Botsot is serving since 2019, incorporated in patna bihar to diliver complete technology requirement for end client with an optimum and world class Solution.</p>
      <br></br>
      <p> Botsot is a system integrator company specializes in bringing together various components or subsystems to create a cohesive and functional system. we offer clients in a variety of industries, such as manufacturing, transportation, energy, and telecommunications, to design, implement, and maintain customized solutions that meet specific needs and requirements.</p>
      <br></br>
      <p>We work closely with clients to understand their goals and objectives, as well as any constraints or challenges they may be facing. We design a system that incorporates the necessary hardware, software, and other components. This may include sourcing and procuring components from various OEM-vendors, as well as developing custom software or programming to control and manage the system.</p>
      <br></br>
      <p>Botsot as a system integrators will typically handle the installation and commissioning of the system, which may involve on-site work to physically install and connect the various components. We also provide training to the client's staff on how to operate and maintain the system.</p>
      <br></br>
      <p>We provide ongoing maintenance and support services to ensure that the system continues to operate smoothly and efficiently. This may include monitoring and troubleshooting, as well as software updates and upgrades.</p>
    </div>
   
    <div class="column-33">
        <img src={img2} alt =''/>
    </div>
  </div>
</div>
  
    </>
  )
}

export default About