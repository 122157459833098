// import logo from './logo.svg';
import React from 'react';
import './App.css';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import Navbar from './component/Navbar/Navbar';
import Business from "./Pages/Business";
import About from "./Pages/About";
import Solution from "./Pages/Solution";
import Contact from "./Pages/Contact";
import Profile from './Pages/Profile';
import Footer from './component/Footer/Footer';

const App =() => {
  return (
    <>
      
          <BrowserRouter>
        <Navbar />  
       
              <Routes>
                <Route path="/" element={<Business/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="/Solution" element={<Solution/>}></Route>
                <Route path="/contact" element={<Contact/>}></Route>
                <Route path="/Profile" element={<Profile/>}></Route>               
              </Routes>
          
           
          
          </BrowserRouter>
        <Footer />
   
    </>
  );
};

export default App;

