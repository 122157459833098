import React from 'react'
import styled from "styled-components";


const Contact = () => {
  const Wrapper = styled.section`



  .common-heading {
    font-size: 3.8rem;
    font-weight: 600;
    margin-bottom: 6rem;
    text-transform: capitalize;
    text-align:center;
    color:#4e42b8;  
   
  }

  padding:9rem 0 5rem 0;

  .container{
    margin-top:6rem;
    text-align:center;
    background:#0C1222;


      .contact-form{
        max-width: 50rem;
        margin:auto;
        padding:10px
        border:2px solid white;
       

        .contact-inputs{
          display:flex;
          flex-direction:column;
          gap:2rem;
          border-radius:50px;

          
        }
      }

    }
  
  
  
  `;
  return (
    <>
    <Wrapper >
<h2 className='common-heading'>Happy to Help you!!</h2>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.5438278517427!2d85.12806232940827!3d25.62007036858246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed596e510558dd%3A0x72937313234ac660!2sBotsot%20Consultancy%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1675177206659!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" titel="map"></iframe>


    <div className='container'>
      <div className='contact-form'>
        <form action='#' method='POST' className='contact-inputs'>
          <input type="text" name ="username" placeholder='username' autoComplete='off' required />
          <input type="email" name ="Email" placeholder='Email' autoComplete='off' required />
          <input type="text" name ="Phone" placeholder='Phone' autoComplete='off' required />
          <textarea name="message" cols="30" rows="6" required></textarea>
          <input type="submit" value="send" className='submit'/>

        </form>
      </div>
    </div>



    </Wrapper>
    <h1>Contact page is Under Maintainance... </h1>
    </>
  )
}

export default Contact