import React from 'react'
import VCard from '../component/Card/VCard';
import BusinessCardData from '../component/Card/BusinessCardData';
import Hero from '../component/Hero/Hero';
import Caursal from '../Pages/Caursal';



const Business = () => {
  return (

      <>  
     <Caursal />
      
        <Hero />      
        <div className="home-cards">
          <h1 className='card_container_title'>Industry We Serve</h1>
          <div className="row">
              <VCard details={BusinessCardData}/>
          </div>
        </div>           
      </>



  );
};

  

export default Business;




