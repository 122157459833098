import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import"./Navbar.css";

const Navbar = () => {
    const [isMobile, setIsMobile] =useState (false);
  return (
    <nav className='navbar'>
        <h3 className='logo'>Botsot</h3>

        <ul className={isMobile? "nav-links-mobile": "nav-link"}
        onClick={() => setIsMobile(false)}
        >
        <Link to= "/" className='Business'>
            <li>Business</li>
        </Link>
        <Link to= "/Solution" className='Solution'>
            <li>Solution</li>
        </Link>
        <Link to= "/about" className='about'>
            <li>About</li>
        </Link>
        <Link to= "/contact" className='contact'>
            <li>Contact</li>
        </Link>
        <Link to= "/profile" className='profile'>
            <li>Profile</li>
        </Link>
        </ul>
        <button className='mobile-menu-icon'
        onClick={() => setIsMobile(!isMobile) }
        >
            {isMobile ? (
                 <i className="fas fa-times"></i>
                 ) : ( 
                 <i className="fas fa-bars"></i>)}
        </button>
    </nav>
  )
}

export default Navbar