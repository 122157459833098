import React from "react";
import"react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../App.css";



const caursal = () => {
  return (
    <div>
<Carousel infiniteLoop autoPlay>
    <div className="image">
        <img src="../img/server.png" alt="Ap"/>        
    </div>

    <div className="image">
        <img src="../img/epbx.png" alt="cloud"/>
    </div>

    <div className="image">
        <img src="../img/comsec.png" alt="network"/>
    </div>

    <div className="image">
        <img src="../img/poly.jpg" alt="terminal"/>
    </div>

    <div className="image">
        <img src="../img/bosch.jpg" alt="img"/>
    </div>
    <div className="image">
        <img src="../img/UPS.png" alt="img"/>
    </div>

</Carousel>

    </div>
  )
}

export default caursal;