import optic from './img/optic.jpg';
import Automation from './img/Automation.jpeg';
import Vertiv from './img/Vertiv.png';
import Clean from './img/Clean.jpg';

const CardData =[
    {
   
        img:optic,
        title:'IT Services',
        description:'We offers a wide range of IT & Low voltage solutions to help businesses of all sizes effectively manage and optimize their technology infrastructure, with a team of experienced professionals who provide expert advice, reliable solutions, and ongoing support. '
    },
    {
        img:Automation,
        title:'Automation',
        description:'We offer automation solutions for residential and commercial properties. We specialize in designing and installing state-of-the-art systems for lighting control, audio/video, security, and home & Industrial automation. '
    },

    {
        img:Vertiv,
        title:'Power-Backup',
        description:" We understand the importance of reliable power for the smooth functioning of any organization, thats why we offer a wide range of backup power solutions including UPS systems, generators, and battery backup systems according to your specific needs."
    },
    {
        img:Clean,
        title:'Clean Energy',
        description:"We specialize in designing and installing renewable energy systems such as solar panels, wind turbines, and energy storage systems. We use only the highest quality products from top manufacturers and our systems are designed to be efficient, reliable, and scalable to accommodate future growth. "
    },
 
];



export default CardData;