import React from 'react'
import Hero from '../component/Hero/Hero';

const Profile = () => {
  return (
    <>
    <Hero/>
    <div style={{  textAlign:'center', }} >
      <a href='Botsot.pdf' download={'Botsot.pdf'}><button className='profile' style={{color: "#ffffff", background:'#4e42b8', margin:'50px auto',}}>Profile Download</button></a>
    </div>
    </>
  )
}

export default Profile