import React from 'react'
// import Business from '../Business';
import './Footer.css';

const Footer = () => {
  return (
    <>
    
   
<footer class="footer-distributed">
			<div class="footer-left">
				<h3>Botsot<span></span></h3>
				{/* <p class="footer-links">
					<a href='#Business' class="link-1">Home</a>					
					<a href="#">Blog</a>				
					<a href="#">Pricing</a>				
					<a href="#">About</a>					
					<a href="#">Contact</a>
				</p> */}

				<p class="footer-company-name">Botsot Consultancy Private Limited. © 2019</p>
			</div>

			<div class="footer-center">
				<div>
					<i class="fa fa-map-marker"></i>
					<p>Anishabad Beur Jail Road<br/>Patna - 800002 <br/> Bihar, India</p>
				</div>
				<div>
					<i class="fa fa-phone"></i>
					<p>+91 8210495258</p>
				</div>
				<div>
					<i class="fa fa-envelope"></i>
					<p><a href="mailto:support@company.com">costumersupport@botsot.com</a></p>
				</div>

			</div>
			<div class="footer-right">
				<p class="footer-company-about">
					<span>Connecting Technologies..</span>					
				</p>

			</div>
		</footer>
    
    
    </>
  )
}

export default Footer