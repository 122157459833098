import React from 'react'
import VCard from '../component/Card/VCard';
import SolutionCardData from '../component/Card/SolutionCardData';


const Solution = () => {
  return (
    <>

<div className='bannerimage' > </div>

    <div className='side-card'>
      <div className="home-cards">
        <div className="row">      
            <VCard details={SolutionCardData}/>            
        </div>
      </div>  
    </div>
</>
  )
}

export default Solution