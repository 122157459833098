import terminal from './img/terminal.jpg';
import nas from './img/nas.jpg';
import security from './img/security.jpg'
import VMS from './img/VMS.jpg';
import audio from './img/audio.jpg';
import video from  './img/video.jpg';
import UPS from './img/UPS.png';
import Voip from './img/Voip.jpg';
import cloud from './img/cloud.jpg';
import Iot from './img/Iot.jpg';
import software from './img/software.jpg';
import solar from './img/solar.jpg';

const CardData =[
    {
        img:nas,
        title:'IT Infrastructure',
        description:"We Provide hardware, software, storage, and data centers, as well as the people and processes that manage and maintain them. It also includes the security measures that protect the infrastructure from threats and unauthorized access."
    },

    {
        img:terminal,
        title:'Network Infrastructure',
        description:" The goal of network infrastructure is to provide a reliable and secure means of communication and data transfer within an organization by deoloying Enterprise grade hardware, as well as the cabling and other physical infrastructure that connects these devices together."
    },

    {
        img:cloud,
        title:'Cloud Solution',
        description:"."
    },

    {
        img:security,
        title:'Security',
        description:"A comprehensive cyber security solution is essential for protecting an organization from various cyber threats, and it should be regularly updated, tested and fine-tuned to adapt to the new and emerging threats."
    },
    {
        img:Voip,
        title:'EPBX-VOIP',
        description:"We help to improve communication and collaboration within an organization and with external partners and customers by providing a centralized system for managing and routing telephone calls. It also helps to reduce costs, increase productivity, and improve customer service by providing advanced call management features."
    },

    {
        img:VMS,
        title:'Surveillance Infrastructure',
        description:'Surveillance can be used for a variety of purposes, such as crime prevention, national security, and public safety. It can also be used in private settings, such as in businesses and homes, to protect against theft or other forms of criminal activity.'
    },
    {
        img:Iot,
        title:'Embedded System',
        description:"IoT solutions typically include a combination of hardware, software, and services, such as sensors, gateways, cloud platforms, and data analytics. These solutions can be tailored to meet the specific needs of different industries and organizations."
    },

    {
        img:audio,
        title:'Audio Solution',
        description:'We serve in all kind of Profesional and Industrial audio solution required in the industry for auditorium, theater, P.A and Confrencing.'
    },
    {
        img:video,
        title:'Video Solution',
        description:"We offer in a verity of video solution starting from video confrencing, Classrom solution, Audiotorium, theater, till Video wall and Outdoor display and much more.. "
    },
    {
        img:software,
        title:'Software Solution',
        description:"We meet the specific needs and requirements of a business or organization. By leveraging cutting-edge technology and a deep understanding of business processes, custom software can streamline operations, improve productivity, and provide a competitive edge."
    },
    {
        img:UPS,
        title:'Power-Backup',
        description:"We provides instant protection against power outages and surges. It ensures a continuous supply of electricity to critical devices, such as computers and servers, keeping them running smoothly and protecting data during power disruptions."
    },
    {
        img:solar,
        title:'Solar Solution',
        description:"Solar solutions harness the power of the sun to provide clean, renewable energy for homes and businesses. With advances in technology and declining costs, switching to solar power is a smart and sustainable choice for reducing energy costs and mitigating the impacts of climate change."
    },






];



export default CardData;